import * as React from "react";
import BurgerIcon from "@material-ui/icons/Dehaze";
import classnames from "classnames";
import logoSvg from "./styles/img/edufy-logo.svg";
import LogoutIcon from "./styles/img/Logout";
import memoizeOne from "memoize-one";
import requireLoginForAction from "./require-login-for-action";
import triggerEvent from "@app/utils/events";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/redux";
import { logout } from "@app/redux/actions/user";
import { match, withRouter } from "react-router-dom";
import { ObjectId } from "@app/utils/generics";
import "./styles/header.min.css";
import { FormattedMessage } from "react-intl";

interface IOwnProps {
	solid?: boolean;
	history: History;
	selectedBlock?: string;
	selectedBlockIndex?: number;
	transparent?: boolean;
	match: match<{ courseId: ObjectId; folderId: ObjectId }>;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IDispatchProps {
	logout: typeof logout;
}

type IProps = IStateProps & IDispatchProps & IOwnProps;

interface IState {
	navbarShorten: boolean;
	shortNavBarOpen: boolean;
	fixed_bar: boolean;
	shortNavBarRemoving: boolean;
}
// tslint:disable-next-line
const header_buttons = "header_buttons ";

const LogoutButton = (props) => (
	<span className="header_buttons header_logout" onClick={props.logout}>
		<span>
			<FormattedMessage id="logout" />
		</span>
		<LogoutIcon width={27} />
	</span>
);

const LoginButton = (props) => (
	<span
		className="header_buttons header_logout"
		onClick={() => requireLoginForAction(() => {})}
	>
		<span>შესვლა</span>
	</span>
);

class Header extends React.Component<IProps, IState> {
	state = {
		fixed_bar: false,
		navbarShorten: false,
		shortNavBarOpen: false,
		shortNavBarRemoving: false,
	};

	navBarRef: React.RefObject<HTMLDivElement>;
	_isMounted: boolean;
	onScrollFunction: Function;
	removeLoader: Function;

	userIsLogged = memoizeOne((isLogged) => {
		if (isLogged) {
			setTimeout(() => {
				this.setState({ shortNavBarOpen: false });
			}, 0);
		}
		return isLogged;
	});

	constructor(props: IProps) {
		super(props);
		this.navBarRef = React.createRef();
	}

	componentDidMount() {
		this._isMounted = true;
		const navbar = this.navBarRef.current;
		this.checkForResize();
		window.addEventListener("resize", this.checkForResize);

		if (typeof this.props.solid !== "undefined") {
			this.setState({ fixed_bar: false });
			const offsetnav = navbar!.offsetTop;
			const stickyNavBar = () => {
				if (window.pageYOffset >= offsetnav) {
					this.setState({ fixed_bar: true });
				} else {
					this.setState({ fixed_bar: false });
				}
			};
			stickyNavBar();
			let lastTimeScroll = Date.now();
			this.onScrollFunction = () => {
				if (Date.now() - lastTimeScroll >= 40) {
					stickyNavBar();
					lastTimeScroll = Date.now();
				}
			};
			window.addEventListener(
				"scroll",
				this.onScrollFunction as (e: Event) => void
			);
		} else {
			this.setState({ fixed_bar: true });
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener(
			"scroll",
			this.onScrollFunction as (e: Event) => void
		);
		window.removeEventListener("resize", this.checkForResize);
	}

	logoutFromThisPage = () => {
		this.props.logout();
		window.scroll(0, 0);
	};

	goto = (path: string) => () => {
		this.props.history.push(path);
		if (path === "/unis/") {
			triggerEvent({
				category: "Button",
				action: "Goto All Unis page",
				label: "from header",
			});
		} else if (path === "/latori/") {
			triggerEvent({
				category: "Button",
				action: "Goto Latori page",
				label: "from header",
			});
		}
	};

	checkForResize = () => {
		const wid = window.innerWidth;
		if (wid < 1200 && !this.state.navbarShorten) {
			this.setState({ navbarShorten: true, shortNavBarOpen: false });
		} else if (wid >= 1200 && this.state.navbarShorten) {
			this.setState({ navbarShorten: false, shortNavBarOpen: false });
		}
	};

	navBarOpenToggle = () => {
		const whatToBecome = !this.state.shortNavBarOpen;
		if (!whatToBecome) {
			this.setState({ shortNavBarRemoving: true });
			setTimeout(() => {
				this.setState({
					shortNavBarOpen: whatToBecome,
					shortNavBarRemoving: false,
				});
			}, 300);
		} else {
			this.setState({
				shortNavBarOpen: whatToBecome,
				shortNavBarRemoving: false,
			});
		}
	};

	returnToHome = () => {
		if (this.props.match.params.courseId) {
			this.props.history.push(
				`/courses/${this.props.match.params.courseId}/guide`
			);
			// TODO: redirect to root folder
		} else {
			this.props.history.push("/");
		}
	};

	logout = () => {
		this.props.logout();
		this.setState({ shortNavBarOpen: false });
	};

	render() {
		const navBarClasses = classnames({
			sticky: !!this.state.fixed_bar,
			"navbar-shorten": this.state.navbarShorten,
		});
		const isLoggedIn = !!this.props.user.loggedIn;
		this.userIsLogged(isLoggedIn);
		let isAdmin = false;
		if (
			isLoggedIn &&
			this.props.user.userData &&
			this.props.user.userData.permissions &&
			this.props.user.userData.permissions.isAdmin
		) {
			isAdmin = true;
		}
		return (
			<React.Fragment>
				<div
					id="navigation_bar"
					ref={this.navBarRef}
					className={navBarClasses}
				>
					<div className="main main2">
						<div id="Logo_div_header">
							<img
								id="logo_header"
								alt="logo"
								src={logoSvg}
								onClick={this.returnToHome}
							/>
						</div>
						{this.state.navbarShorten && (
							<div
								id="navBarBurger"
								onClick={this.navBarOpenToggle}
							>
								<div className="VM">
									<BurgerIcon />
								</div>
							</div>
						)}
						{this.state.navbarShorten &&
							this.state.shortNavBarOpen && (
								<div
									className={
										"navBarBlackCurtain " +
										(this.state.shortNavBarRemoving
											? "removing_soon "
											: "")
									}
									onClick={this.navBarOpenToggle}
								/>
							)}
						{(!this.state.navbarShorten ||
							this.state.shortNavBarOpen) && (
							<div
								className={
									"links_headers " +
									(this.state.shortNavBarRemoving
										? "removing_soon "
										: "")
								}
							>
								<div className="VM">
									<React.Fragment>
										<div>
											{isAdmin && (
												<span
													className={header_buttons}
													onClick={this.goto(
														"/admin/appeals"
													)}
												>
													<FormattedMessage id="appeals" />
												</span>
											)}
											{isAdmin && (
												<span
													className={header_buttons}
													onClick={this.goto(
														"/admin/courses"
													)}
												>
													<FormattedMessage id="courses" />
												</span>
											)}
											{isAdmin && (
												<span
													className={header_buttons}
													onClick={this.goto(
														"/admin/test-types"
													)}
												>
													<FormattedMessage id="testTypes" />
												</span>
											)}
											{/* {isAdmin && (
												<span
													className={
														header_buttons +
														(this.props
															.selectedBlock ===
														"admin"
															? "selected "
															: "")
													}
													onClick={this.goto(
														"/admin/card-templates"
													)}
												>
													ბარათების თარგები
												</span>
											)} */}
											{isLoggedIn && (
												<LogoutButton
													logout={this.logout}
												/>
											)}
											{!isLoggedIn && <LoginButton />}
										</div>
									</React.Fragment>
								</div>
							</div>
						)}
						<div style={{ clear: "both" }} />
					</div>
				</div>

				<div
					id="navigation_bar_fake"
					className={this.state.fixed_bar ? "visible" : undefined}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	user: state.user,
});

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	{ logout }
)(withRouter(Header as any) as any as typeof Header);
