import * as React from "react";
import classNames from "classnames";
import LoadingSVG from "@app/components/styles/img/loading-white";
import Popup from "./widgets/Popup";
import { _console } from "@app/commonJavascript";
import { connect } from "react-redux";
import { ILogin, login } from "@app/redux/actions/user";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import LogoSrc from "./gita.png";

interface IOwnProps {
	onClose: (loggedInSuccessfully: boolean) => void;
	onSuccess?: Function;
}

type IStateProps = null;

interface IDispatchProps {
	login: ILogin;
}

interface IErrorType {
	mail?: boolean;
	password?: boolean;
}

interface IState {
	mail: string;
	password: string;
	signLoading: boolean;
	errors: IErrorType;
}

type IProps = IOwnProps & IDispatchProps;

class LoginPopup extends React.Component<IProps> {
	_isMounted = false;
	state = {
		mail: "",
		password: "",
		signLoading: false,
		errors: {},
	} as IState;
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	onMailChange = (e) => {
		this.setState({
			mail: e.target.value,
			errors: { ...this.state.errors, mail: false },
		});
	};
	onPasswordChange = (e) => {
		this.setState({
			password: e.target.value,
			errors: { ...this.state.errors, password: false },
		});
	};

	onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			this.onLoginClick();
		}
	};

	onLoginClick = () => {
		if (this.state.signLoading) {
			return;
		}
		if (!this.state.mail || !this.state.password) {
			this.setState({
				errors: {
					mail: !this.state.mail,
					password: !this.state.password,
				},
			});
			return;
		}
		this.setState({
			signLoading: true,
		});
		this.props
			.login({
				mail: this.state.mail,
				password: this.state.password,
			})
			.then((data) => {
				if (!this._isMounted) {
					return;
				}
				this.setState({
					signLoading: false,
				});
				this.props.onClose(true);
				if (this.props.onSuccess) this.props.onSuccess();
			})
			.catch((err) => {
				_console.error(err);
				if (!this._isMounted) {
					return;
				}
				const errors: IErrorType = {};
				if (err.response && err.response.data) {
					if (err.response.data.mail) {
						errors.mail = true;
					}
					if (err.response.data.password) {
						errors.password = true;
					}
				}
				this.setState({
					errors,
					signLoading: false,
				});
			});
	};

	onMurtskuRegisterClick = () => {
		window.location.href = "https://murtsku.com/index.php?reg";
	};

	onMurtskuResetPasswdClick = () => {
		window.location.href = "https://murtsku.com/index.php?reset";
	};

	onWhatIsMurtskuClick = () => {
		window.open("https://murtsku.com/", "__blank");
	};

	render() {
		let errorText = "";
		if (
			this.state.mail &&
			this.state.errors.mail &&
			!this.state.errors.password
		) {
			errorText = "ელფოსტა არასწორია";
		} else if (
			this.state.password &&
			this.state.errors.password &&
			!this.state.errors.mail
		) {
			errorText = "პაროლი არასწორია";
		} else if (this.state.errors.mail || this.state.errors.password) {
			errorText = "ელფოსტა ან პაროლი არასწორია";
		}
		return (
			<Popup onClose={() => this.props.onClose(false)}>
				<div className="loginPopupContent">
					<div className="Header">
						<FormattedMessage id="loginPopupHeader" />
					</div>
					<div className="Body">
						<div style={{ textAlign: "center" }}>
							<img
								src={LogoSrc}
								alt="Gita logo"
								style={{ maxHeight: 90, maxWidth: "90%" }}
							/>
						</div>
						<div className="Input">
							<input
								type="text"
								name="mail"
								className={classNames({
									"no-arrows": true,
									incorrect: !!this.state.errors.mail,
								})}
								value={this.state.mail}
								onChange={this.onMailChange}
								onKeyPress={this.onKeyPress}
								placeholder={getFormattedMessage(
									"inputs.email"
								)}
							/>
							<input
								type="password"
								className={classNames({
									incorrect: !!this.state.errors.password,
								})}
								value={this.state.password}
								onChange={this.onPasswordChange}
								onKeyPress={this.onKeyPress}
								placeholder={getFormattedMessage(
									"inputs.password"
								)}
							/>
						</div>
						{errorText && <span>{errorText}</span>}
						<div className="Buttons">
							<button onClick={this.onLoginClick}>
								<span>
									<FormattedMessage id="login" />
								</span>
								{this.state.signLoading && (
									<LoadingSVG width={25} height={25} />
								)}
							</button>
							{/* <button onClick={this.onMurtskuResetPasswdClick}>
								<FormattedMessage id="forgotPassword?" />
							</button> */}
							{/* <button onClick={this.onMurtskuRegisterClick}>
								მურწყუზე რეგისტრაცია
							</button> */}
						</div>
					</div>
				</div>
			</Popup>
		);
	}
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(null, {
	login,
} as any as IDispatchProps)(LoginPopup);
