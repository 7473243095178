/* eslint-disable no-restricted-globals */
import { IModelingTest } from "@app/api/modeling/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { IRTest, IUserTestQuestionInfo } from "@app/api/tests/helper-schemas";
import { inject } from "@app/modules";
import triggerEvent from "@app/utils/events";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import { tryPromiseMultipleTimes } from "@app/utils/promises";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import {
	IFinishPageProps,
	ITestFinishArgs,
} from "@tests-core/components/tests";
import {
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { History } from "history";
import * as React from "react";
import { useMemo } from "react";
import { connect } from "react-redux";
import { match, useRouteMatch } from "react-router-dom";
import { PrimaryButton } from "../styles/buttons";
import SvgLoading from "../styles/img/loading";
import { TestQuestion } from "../users/tests/old-test-navigation";
import { UniversalTestWrapper2 } from "../users/tests/wrapper-2";
import { openConfirmationPopup } from "../widgets/confirmation-popup";
import Popup, { PopupContent } from "../widgets/Popup";
import TouLogoSrc from "./imgs/diig.jpg";
import { testDesign } from "./public";
import styles from "./styles.module.css";
import { IFinishComponentProps } from "./test";

interface IOwnProps {
	match: match<{ modellingId: ObjectId }>;
	history: History;
}

enum Mode {
	beginning,
	test,
	review,
	end,
}

interface IState {
	mode: Mode;
	loading: boolean;
	questions?: (IShortQuestion | IFullQuestion)[];
	texts?: IText[];
	test?: IRTest;
	modellingTest?: IModelingTest;
	defaultCheckedAnswers?: IUserTestQuestionInfo[];
	startedAt?: Date;
	duration?: number;
}

class Modelling extends React.Component<IOwnProps, IState> {
	modellingId = +this.props.match.params.modellingId;

	state: IState = {
		mode: Mode.beginning,
		loading: true,
	};

	resultsPage = this.props.history.location.search.indexOf("results") > -1;

	_ModelingTestsController = inject("ModelingController");

	componentDidMount() {
		this._ModelingTestsController
			.get({ testId: this.modellingId })
			.then((test) => {
				this.setState({
					loading: false,
					modellingTest: test,
				});
				if (this.resultsPage) {
					localStorage.setItem("has-clicked-survey", "true");
					this.onStartReviewing().then(() => {
						setTimeout(() => {
							this.forceFullyFinish();
						}, 100);
					});
				}
			})
			.catch((e) => {
				alert("დაფიქსირდა შეცდომა");
			});
	}

	onBeginTest = () => {
		this.setState({
			loading: true,
		});
		tryPromiseMultipleTimes({
			delayBetweenTrials: 100,
			getPromise: () =>
				this._ModelingTestsController
					.begin({ testId: this.modellingId })
					.then((data) => {
						console.log(data);
						this.setState({
							mode: Mode.test,
							questions: data.questions,
							texts: data.texts,
							test: data.test,
							loading: false,
							startedAt: data.startedAt,
							defaultCheckedAnswers: !data.answeredQuestionsInfo
								? undefined
								: data.answeredQuestionsInfo.map((e) => ({
										id: e.id,
										userAnswer: e.userAnswer,
										answeredAt: e.answeredAt,
										numAttempts: e.numAttempts,
										credit: e.credit || 0,
									})),
						});

						triggerEvent(
							{
								category: "Test",
								action: "Modelling test",
								label: "start",
							},
							{
								modellingId: +this.modellingId,
							}
						);
					}),
			maxTrials: 2,
		});
	};

	onStartReviewing = () => {
		this.setState({
			loading: true,
		});
		return this._ModelingTestsController
			.review({ testId: this.modellingId })
			.then((data) => {
				this.setState({
					mode: Mode.review,
					questions: data.questions as IShortQuestion[],
					texts: data.texts,
					loading: false,
					test: data.test,
					defaultCheckedAnswers: !data.answeredQuestionsInfo
						? []
						: data.answeredQuestionsInfo.map((e) => ({
								id: e.id,
								userAnswer: e.userAnswer,
								answeredAt: e.answeredAt,
								numAttempts: e.numAttempts,
								credit: e.credit || 0,
							})),
				});
			});
	};

	save = async (args: ITestFinishArgs) => {
		const after = () => {
			this.setState({
				loading: false,
				mode: Mode.end,
			});
		};
		if (this.resultsPage) {
			return Promise.resolve().then(after);
		}
		return this._ModelingTestsController
			.save({
				testId: this.modellingId,
				answers: args.userAnswers
					.filter(
						(e) =>
							!!e &&
							e.userAnswer !== null &&
							e.userAnswer !== undefined
					)
					.map((e) => ({
						id: e.questionId,
						userAnswer: e.userAnswer!,
						answeredAt: e.dates.lastAnsweredAt || new Date(),
					})),
			})
			.then(after);
	};

	handleTestError = (e: any) => {
		openConfirmationPopup({
			text: getFormattedMessage(
				"დაფიქსირდა შეცდომა. თქვენი ნაწერი ვერ შეინახა. სცადეთ თავიდან დაწერა"
			),
		});
		throw e;
	};

	onFinish = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args),
			maxTrials: 6,
			delayBetweenTrials: 100,
		})
			.then(() => {
				triggerEvent(
					{
						category: "Test",
						action: "Modelling test",
						label: "finish",
					},
					{
						modellingId: +this.modellingId,
					}
				);
			})
			.catch(this.handleTestError);
	};
	onSave = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};

	finishComponent = (props: IFinishComponentProps) => {
		const unAnsweredQuestionIndices: number[] = [];
		for (let i = 0; i < props.questionsLength; i++) {
			if (
				props.userAnswers[i] === undefined ||
				props.userAnswers[i] === null
			) {
				unAnsweredQuestionIndices.push(i);
			}
		}
		return (
			<div style={{ textAlign: "center" }}>
				ნამდვილად გსურთ ტესტის დასრულება?
				<br />
				{unAnsweredQuestionIndices.length >= 1 && (
					<div>
						თქვენ არ გიპასუხიათ შემდეგი{" "}
						{unAnsweredQuestionIndices.length === 1
							? "კითხვისთვის"
							: "კითხვებისთვის"}
						:
						<div style={{ textAlign: "center" }}>
							{unAnsweredQuestionIndices.map((e) => (
								<TestQuestion
									key={e}
									index={e}
									isAccessable={true}
									isSelected={false}
									onQuestionSelect={props.onQuestionSelect}
								>
									{e + 1}
								</TestQuestion>
							))}
						</div>
					</div>
				)}
				<PrimaryButton onClick={props.onFinish}>
					დასრულება
				</PrimaryButton>
			</div>
		);
	};

	reviewComponent = (props: IFinishComponentProps) => {
		const totalScore = props.answersCorrectedness.reduce(
			(sum: number, score) => (sum += Math.floor(score || 0)),
			0
		);
		return (
			<div style={{ textAlign: "center" }}>
				თქვენ დაასრულეთ მოდელირების ნაწერის გადახედვა <br />
				ჯამური ქულა: {totalScore}
			</div>
		);
	};

	modelingIsFinished = () => {
		if (!this.state.modellingTest) throw new Error();
		if (this.state.modellingTest.finish_time.getTime() < Date.now()) {
			return true;
		}
		return false;
	};

	canReviewNow = () => {
		if (!this.state.modellingTest) throw new Error();
		if (!this.state.modellingTest.review_start_time) return false;
		if (this.state.modellingTest.review_start_time.getTime() > Date.now()) {
			return false;
		}
		if (!this.state.modellingTest.review_finish_time) return true;
		return (
			this.state.modellingTest.review_finish_time.getTime() > Date.now()
		);
	};

	WrapperRef = React.createRef<UniversalTestWrapper2>();

	preFinishHook = (): Promise<{ finish: boolean }> => {
		if (this.resultsPage) {
			return Promise.resolve({ finish: true });
		}
		return new Promise((resolve, reject) => {
			openConfirmationPopup({
				text: "ნამდვილად გსურთ მოდელირების დასრულება?",
				approveTitle: getFormattedMessage("yes"),
				rejectTitle: getFormattedMessage("no"),
				onApprove: () => {
					resolve({ finish: true });
				},
				onReject: () => {
					resolve({ finish: false });
				},
			});
		});
	};

	forceFullyFinish = async () => {
		const test = this.WrapperRef.current?.testComponentRef.current;
		if (!test) return;
		test.safeFinish().catch(() => {
			//
		});
	};

	onGotoNext = () => {
		//
	};
	redirectoToFolder = () => {
		//
	};

	testTypeSettings: ITestTypeSettings = {
		allowSwitchingToSubmittedQuestions: true,
		allowSwitchingToUnsubmittedQuestions: true,
		checkInBackEnd: false,
		maxNumOfWritingTests: 1,
		showAnswersAt: TestTypeAnswersShowTime.afterDeadline,
		submitAnswerAfterAnswering: false,
	};

	render() {
		const header = (
			<div style={{ textAlign: "center", marginTop: 20 }}>
				<img
					src={TouLogoSrc}
					alt="eu logo"
					style={{ maxHeight: 120, maxWidth: "90%" }}
				/>
			</div>
		);

		if (this.state.loading || !this.state.modellingTest) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}
		if (this.state.mode === Mode.beginning) {
			if (this.modelingIsFinished()) {
				if (!this.canReviewNow()) {
					return (
						<div style={{ textAlign: "center" }} className="main">
							<h2>მოდელირება დასრულებულია</h2>
						</div>
					);
				}
				return (
					<div
						style={{ textAlign: "center", fontFamily: "FiraGo" }}
						className="main"
					>
						<h2>მოდელირების გადახედვა</h2>
						<PrimaryButton onClick={this.onStartReviewing}>
							დაწყება
						</PrimaryButton>
					</div>
				);
			}
			return (
				<div
					style={{
						textAlign: "center",
						fontFamily: "FiraGO",
					}}
					className="main"
				>
					<div
						className={
							styles.largeTextContainer /* styles.smallContainer */
						}
					>
						{header}
						<h1>{this.state.modellingTest.name}</h1>
						{this.state.modellingTest.id === 19 && ( // ისტორია
							<HistInstructions />
						)}
						{this.state.modellingTest.id === 20 && ( // ინგლისური
							<EngInstructions />
						)}
						<br />
						<br />
						<PrimaryButton onClick={this.onBeginTest}>
							დაწყება
						</PrimaryButton>
					</div>
				</div>
			);
		}
		if (!this.state.questions || !this.state.texts || !this.state.test) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}
		return (
			<div style={{ fontFamily: "FiraGo" }}>
				{header}
				<UniversalTestWrapper2
					ref={this.WrapperRef}
					test={this.state.test}
					testTypeSettings={this.testTypeSettings}
					content={{
						cards: [],
						questions: this.state.questions,
						texts: this.state.texts,
					}}
					defaultUserAnswers={this.state.defaultCheckedAnswers || []}
					onFinish={this.onFinish}
					onSave={this.onSave}
					onGotoNext={this.onGotoNext}
					currentAttempt={1}
					FinishPageComponent={FinishPageComponent}
					testNavigationProps={{
						styles: testDesign,
						showFinishPageIcon: true,
					}}
					preFinishHook={this.preFinishHook}
					startedAt={this.state.startedAt}
					duration={this.state.modellingTest.max_duration}
					forceFullyFinish={this.forceFullyFinish}
				/>
				<div
					className="main"
					style={{
						padding: "0 20px",
						marginTop: "-20px",
					}}
				>
					{this.state.mode !== Mode.end &&
						this.state.mode !== Mode.review && (
							<PrimaryButton
								onClick={this.forceFullyFinish}
								style={{ backgroundColor: "#a375ff" }}
							>
								დასრულება
							</PrimaryButton>
						)}
				</div>
				<br />
				<br />
				<br />
			</div>
		);
		// return null;
	}
}

const HistInstructions = React.memo<{}>(function HistInstructions({}) {
	return (
		<span style={{ display: "block", textAlign: "left" }}>
			შენ იწყებ ეროვნული გამოცდების მოდელირების წერას{" "}
			<b>
				<u>ისტორიაში</u>
			</b>
			, რომლის მაქსიმალური ქულაა{" "}
			<b>
				<u>45 ქულა</u>
			</b>{" "}
			(ნაცვლად 60-სა), ხოლო{" "}
			<b>
				<u>წერის დრო - 90 წთ</u>
			</b>{" "}
			(ნაცვლად 160 წთ-სა). გამოცდა მოიცავს ყველა ტიპის სავარჯიშოს, გარდა
			არგუმენტაციის დავალებისა.
			<br />
			<br />
			ვინაიდან მოდელირება მიმდინარეობს ონლაინ ფორმატში, ღია კითხვის ტიპები
			წარმოდგენილი იქნება შემცირებული რაოდენობით. გაითვალისწინეთ, რომ{" "}
			<b>
				<u>ნაშრომი გასწორდება ელექტრონული პროგრამის მიერ,</u>
			</b>{" "}
			ავტომატურად, ამიტომ მნიშვნელოვანია, რომ საკუთარი პასუხები დააფიქსირო
			მოკლედ, კონკრეტულად და ამომწურავად.
			<br />
			<br />
			<b>მოდელირებაში ქულების ნაწილდება შემდეგნაირად:</b>
			<br />
			ტესტური სავარჯიშოები - 15 ქულა
			<br />
			ქრონოლოგიური სავარჯიშოები - 3 ქულა
			<br />
			ერთი კონკრეტული მოვლენის აღმწერი 2 სხვადასხვა ტიპის წყარო - 4 ქულა
			<br />
			ილუსტრაციის ანალიზი - 4 ქულა
			<br />
			ისტორიული რუკის ანალიზი - 8 ქულა (ნაცვლად 11 ქულისა)
			<br />
			1 ისტორიული მოვლენის შეფასება (ნაცვლად 3-სა) - 2 ქულა (ნაცვლად 6
			ქულისა)
			<br />
			ისტორიული დოკუმენტის ანალიზი - 9 ქულა (ნაცვლად 17 ქულისა)
			<br />
			<br />
			<b>
				<u>შენი ჯამური შედეგის ნახვას შეძლებ წერის დასრულებისთანავე,</u>
			</b>{" "}
			ხოლო სწორ პასუხებზე წვდომა გექნება ორშაბათს, 7 ივნისს, 12:00-დან
			დღის ბოლომდე.
			<br />
			<br />
			<b>აბა, შენ იცი, წარმატებები!</b>
		</span>
	);
});

const EngInstructions = React.memo<{}>(function EngInstructions({}) {
	return (
		<span
			style={{ display: "block", textAlign: "left", marginBottom: -20 }}
		>
			შენ იწყებ ეროვნული გამოცდების მოდელირების წერას ინგლისურში, რომლის
			მაქსიმალური ქულაა{" "}
			<b>
				<u>58 ქულა</u>
			</b>{" "}
			(ნაცვლად 80-სა), ხოლო{" "}
			<b>
				<u>წერის დრო - 110 წთ</u>
			</b>{" "}
			(ნაცვლად 150 წთ-სა).
			<br />
			<br />
			გამომდინარე იქიდან, რომ{" "}
			<b>
				<u>ნაშრომი სწორდება ელექტრონული პროგრამის მიერ</u>
			</b>{" "}
			- გამოცდა მოიცავს ყველა ტიპის სავარჯიშოს, გარდა წერილისა და ესეს
			სავარჯიშოებისა. ერთიან ეროვნულ გამოცდაზე ამ საკითხზე ჯამური ქულა
			80-დან 22-ია. დანარჩენი საკითხები კი წარმოდგენილია იმ ფორმით,
			რაოდენობით და სირთულესთან მაქსიმალურად მიახლოებულად, როგორც ისინი
			ერთიან ეროვნულ გამოცდებზე მოგეთხოვება.
			<br />
			<br />
			<b>
				<u>შენი ჯამური შედეგის ნახვას შეძლებ წერის დასრულებისთანავე,</u>
			</b>{" "}
			ხოლო სწორ პასუხებზე წვდომა გექნება ორშაბათს, 7 ივნისს, 12:00-დან
			დღის ბოლომდე.
			<br />
			<br />
			<b>აბა, შენ იცი, წარმატებები!</b>
		</span>
	);
});

export default connect<null, null, IOwnProps>(null, null)(Modelling);

const FinishPageComponent: React.FC<IFinishPageProps> = (props) => {
	const maxScore = useMemo(() => {
		try {
			let sum = 0;
			for (const q of props.questions) {
				sum += newContent((q as IFullQuestion).content).getMaxCredit();
			}
			return sum;
		} catch (e) {
			console.warn(e);
		}
		return 0;
	}, [props.questions]);

	const score = props.info.totalCredit;

	const showPopup = 1 > 2 && !localStorage.getItem("has-clicked-survey");

	return (
		<div>
			{showPopup && <EuroUniPopup maxScore={maxScore} score={score} />}
			<div
				style={{
					fontFamily: "FiraGo",
					textAlign: "center",
					color: "#5273e6",
					fontSize: 22,
				}}
			>
				<span
					style={{ fontSize: 27, marginBottom: 10, display: "block" }}
				>
					შენ დააგროვე {score} ქულა {maxScore}-დან.
				</span>
				<br />
				სწორი პასუხების ნახვას შეძლებ:
				<br />
				<br />
				<b>ორშაბათს, 7 ივნისს, 12:00-დან დღის ბოლომდე.</b>
			</div>
		</div>
	);
};

const EuroUniPopup: React.FC<{
	maxScore: number;
	score: number;
	onClose?: () => void;
}> = React.memo(
	({
		maxScore,
		score,
		onClose = () => {
			//
		},
	}) => {
		const match = useRouteMatch<{ modellingId: string }>();
		const modellingId = +match.params.modellingId;
		const handleFillingSurvey = () => {
			localStorage.setItem("has-clicked-survey", "true");
			const prefix = "https://murtsku.com";
			location.href = `${prefix}/eu-survey.php?modelling=${modellingId}`;
		};

		return (
			<Popup onClose={onClose}>
				<PopupContent
					style={{
						padding: 0,
						width: 600,
						maxWidth: "95%",
						fontFamily: "FiraGo",
						textAlign: "center",
					}}
					className={styles.euroUniPopupContent}
				>
					<div className={styles.euroUniHeader} />
					<div style={{ padding: "10px 30px" }}>
						<img
							src={TouLogoSrc}
							alt="eu logo"
							style={{
								height: 70,
								maxWidth: "90%",
								margin: "20px 0",
							}}
						/>
						<br />
						<br />
						გილოცავ, შენ წარმატებით დაასრულე ტესტის წერა.
						{/* <b
							style={{
								background: "#81ba52",
								display: "inline-block",
								padding: 5,
								color: "white",
								borderRadius: 5,
							}}
						>
							{score} ქულა
						</b>{" "}
						მაქსიმალური {maxScore} ქულიდან.
						<br />
						<br /> */}
						<br />
						<br />
						<b>
							ქულისა და შესწორებული ნაშრომის სანახავად, გთხოვთ,
							შეავსოთ ევროპის უნივერსიტეტის კითხვარი,
						</b>{" "}
						რომელიც შეეხება აბიტურიენტის მიერ უნივერსიტეტის შერჩევის
						პროცესს.
						<br />
						<button
							className={styles.euMainButton}
							onClick={handleFillingSurvey}
						>
							კითხვარის შევსება
						</button>
					</div>
				</PopupContent>
			</Popup>
		);
	}
);
